import ButtonPrimary from "../Components/ButtonPrimary";
import ButtonSecondary from "../Components/ButtonSecondary";
import { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

export default function Modal({
  children,
  open = false,
  setOpen,
  handleConfirm,
  title = "",
  maxWidth = "2xl",
  informationType,
  confirmButtonLabel = "Confirm",
  closeButtonLabel = "Cancel",
  handleClose = true,
  className = "bg-white",
}) {
  const maxWidthClass = {
    sm: "sm:max-w-sm",
    md: "sm:max-w-md",
    lg: "sm:max-w-lg",
    xl: "sm:max-w-xl",
    "2xl": "sm:max-w-2xl",
    "3xl": "sm:max-w-3xl",
    "4xl": "sm:max-w-4xl",
    "5xl": "sm:max-w-5xl",
    "6xl": "sm:max-w-6xl",
    "7xl": "sm:max-w-7xl",
    fit: "max-w-fit",
  }[maxWidth];

  useEffect(() => {
    //document.body.style.height = `calc(100vh - ${document.body.style.marginTop})`;
    //open ? window.scrollTo({ top: 0, left: 0, behavior: "smooth" }) : null;
    open ? (document.body.style.overflowY = "hidden") : null;
    return () => {
      document.body.style.overflowY = "unset";
    };
  }, [open]);

  const iconDiv = (
    <div>
      {informationType === "Warning" ? (
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
      ) : null}
    </div>
  );
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={
                  `relative transform overflow-hidden rounded-lg px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:mx-auto sm:p-6 ${maxWidthClass} ` +
                  className
                }
              >
                <div className="absolute right-0 top-0 block pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md  text-gray-400 hover:text-gray-500 "
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="sm:flex sm:items-start">
                  {iconDiv}
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                  </div>
                </div>
                <div>{children}</div>

                {handleConfirm}
                <div
                  className={
                    handleConfirm || handleClose
                      ? `mt-10 sm:mt-8 sm:flex sm:flex-row-reverse`
                      : `mt:0`
                  }
                >
                  {handleConfirm && (
                    <ButtonPrimary
                      type="button"
                      className="inline-flex w-full justify-center rounded-md sm:ml-3 sm:w-auto"
                      onClick={handleConfirm}
                    >
                      {confirmButtonLabel}
                    </ButtonPrimary>
                  )}
                  {handleClose && (
                    <ButtonSecondary
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                    >
                      {closeButtonLabel}
                    </ButtonSecondary>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
