export default function ButtonSecondary({
  className = "",
  disabled = false,
  type = "button",
  children,
}) {
  return (
    <button
      type={type}
      className={
        `inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ${
          disabled && "opacity-25"
        } ` + className
      }
      disabled={disabled}
    >
      {children}
    </button>
  );
}
