const injectCSS = () => {
  // Create a <link> element
  var link = document.createElement("link");

  // Set the link type to and rel attributes
  link.type = "text/css";
  link.rel = "stylesheet";

  if (process.env.NODE_ENV == "production") {
    // A CDN link to your production CSS
    link.href = process.env.REACT_APP_DOMAIN + "index.61d3354a.css";
  } else {
    // Your local CSS for local development
    link.href = process.env.REACT_APP_DOMAIN + "index.61d3354a.css"; //"C:/Dev/employee-widget/dist/index.61d3354a.css";
  }

  // Append the stylesheet to the <head> of the DOM
  var head = document.head;
  return Promise.resolve(head.appendChild(link));
};

export default injectCSS;
