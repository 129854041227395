export default function Spinner({ show, ...props }) {
  return (
    <>
      {show ? (
        <div className="w-full min-h-screen flex justify-center items-center fixed inset-0 z-50">
          <div className="flex min-h-screen w-full items-center justify-center">
            <div className="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
              <div className="border-t-transparent border-solid animate-spin  rounded-full border-cyan-500 border-4 h-10 w-10"></div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
