import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

export default function SearchBar({ keyword, onChange, children, forwardRef }) {
  return (
    <div className="mt-3 w-full overflow-hidden">
      <div className="relative text-gray-400 focus-within:text-gray-600">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
        </div>
        <input
          id="search"
          autoFocus
          className="w-full overflow-hidden rounded-md border-0 py-1.5 px-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-800 text-sm"
          placeholder={"Search for name or service"}
          type="search"
          name="search"
          value={keyword}
          onChange={(e) => onChange(e.target.value)}
          ref={forwardRef}
        />
      </div>
    </div>
  );
}
