import React, { useState } from 'react';

export default function Tooltip ({ content, children }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <div className="absolute bg-gray-500 text-white text-xs py-1 px-2 rounded z-10 bottom-full">
           {content}
        </div>
      )}
    </div>
  );
};
